import {
  Box,
  Button,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  CircularProgress,
  AlertTitle,
  Alert,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, {
  useState,
  useEffect,
  useCallback,
  FC,
  ReactElement,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import useAuth from "../../hooks/useAuth";
import { getApiBaseUrl } from "../../services/baseUrl";
import { Layout } from "./layout";

interface FormValues {
  usuarioRevenda: string;
  senhaRevenda: string;
  usuarioAdmin: string;
  senhaAdmin: string;
  cliente: string;
  token: string;
  submit: "login" | "sendToken" | null;
}

interface SignInProps {}

interface SignInComponent extends FC<SignInProps> {
  getLayout?: (page: ReactElement) => ReactNode;
}

const SignIn: SignInComponent = () => {
  const { signin } = useAuth();
  const navigate = useNavigate();
  const API_BACK = getApiBaseUrl();
  const [method, setMethod] = useState<"REVENDA" | "ADMIN">("REVENDA");
  const [isPanelActive, setIsPanelActive] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    axios
      .get(`${API_BACK}/v1/tokenExistente`)
      .then((response) => {
        if (response.data.response === "Token encontrado.") {
          setIsPanelActive(true);
        } else if (response.data.response === "Token não encontrado.") {
          setIsPanelActive(false);
        }
      })
      .catch((error) => {
        console.error("Erro ao buscar token:", error);
      });
  }, [method]);

  const revendaSchema = Yup.object({
    usuarioRevenda: Yup.string().max(255).required("Usuário é obrigatório"),
    senhaRevenda: Yup.string().max(255).required("Senha é obrigatória"),
  });

  const adminSchema = Yup.object({
    usuarioAdmin: Yup.string().max(255).required("Usuário é obrigatório"),
    senhaAdmin: Yup.string().max(255).required("Senha é obrigatória"),
    cliente: isPanelActive
      ? Yup.string()
      : Yup.string().required("Cliente é obrigatório"),
    token: isPanelActive
      ? Yup.string()
      : Yup.string().required("Token é obrigatório"),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      usuarioRevenda: "",
      senhaRevenda: "",
      usuarioAdmin: "",
      senhaAdmin: "",
      cliente: "",
      token: "",
      submit: null,
    },
    validationSchema: method === "REVENDA" ? revendaSchema : adminSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (method === "ADMIN") {
        const response = await signin(
          values.usuarioAdmin,
          values.senhaAdmin,
          values.cliente,
          values.token,
          method,
          isPanelActive,
        );

        setSubmitting(false);
        if (!response.success) {
          setErrorMessage(response.message || "Ocorreu um erro desconhecido.");
        } else {
          setErrorMessage("");
          navigate("/home");
        }
      }

      // Lógica para revenda
      if (method === "REVENDA") {
        const response = await signin(
          values.usuarioRevenda,
          values.senhaRevenda,
          values.cliente,
          values.token,
          method,
          isPanelActive,
        );

        setSubmitting(false);
        if (!response.success) {
          setErrorMessage(response.message || "Ocorreu um erro desconhecido.");
        } else {
          setErrorMessage("");
          navigate("/home");
        }
      }
    },
  });

  const handleMethodChange = useCallback(
    (event: React.SyntheticEvent, value: "REVENDA" | "ADMIN") => {
      setMethod(value);
    },
    [],
  );

  return (
    <Layout>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Painel Turbo</Typography>
            </Stack>
            <Tabs onChange={handleMethodChange} sx={{ mb: 3 }} value={method}>
              <Tab label="Revenda" value="REVENDA" />
              <Tab label="Admin" value="ADMIN" />
            </Tabs>
            {method === "REVENDA" && (
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    error={Boolean(
                      formik.touched.usuarioRevenda &&
                        formik.errors.usuarioRevenda,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.usuarioRevenda &&
                      formik.errors.usuarioRevenda
                    }
                    label="Usuário"
                    name="usuarioRevenda"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    autoComplete="username"
                    value={formik.values.usuarioRevenda}
                  />
                  <TextField
                    error={Boolean(
                      formik.touched.senhaRevenda && formik.errors.senhaRevenda,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.senhaRevenda && formik.errors.senhaRevenda
                    }
                    label="Senha"
                    name="senhaRevenda"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="password"
                    autoComplete="current-password"
                    value={formik.values.senhaRevenda}
                  />
                </Stack>
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Acessar"
                  )}
                </Button>
                {errorMessage && (
                  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                    {errorMessage}
                  </Typography>
                )}
                <Alert severity="warning" sx={{ mt: 3 }}>
                  <AlertTitle>Alerta</AlertTitle>
                  Painel suscetível a letras maiúsculas e minúsculas para
                  acesso.
                </Alert>
              </form>
            )}
            {method === "ADMIN" && (
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    error={Boolean(
                      formik.touched.usuarioAdmin && formik.errors.usuarioAdmin,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.usuarioAdmin && formik.errors.usuarioAdmin
                    }
                    label="Usuário"
                    name="usuarioAdmin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    autoComplete="username"
                    value={formik.values.usuarioAdmin}
                  />
                  <TextField
                    error={Boolean(
                      formik.touched.senhaAdmin && formik.errors.senhaAdmin,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.senhaAdmin && formik.errors.senhaAdmin
                    }
                    label="Senha"
                    name="senhaAdmin"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="password"
                    autoComplete="current-password"
                    value={formik.values.senhaAdmin}
                  />
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Box
                      sx={{
                        width: 14,
                        height: 14,
                        borderRadius: "50%",
                        backgroundColor: isPanelActive ? "green" : "red",
                        marginRight: 1,
                      }}
                    />
                    {isPanelActive ? "Painel Ativo" : "Painel Desativado"}
                  </Box>
                  {!isPanelActive && (
                    <>
                      <TextField
                        error={Boolean(
                          formik.touched.cliente && formik.errors.cliente,
                        )}
                        fullWidth
                        helperText={
                          formik.touched.cliente && formik.errors.cliente
                        }
                        label="Cliente"
                        name="cliente"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.cliente}
                      />
                      <TextField
                        error={Boolean(
                          formik.touched.token && formik.errors.token,
                        )}
                        fullWidth
                        helperText={formik.touched.token && formik.errors.token}
                        label="Token"
                        name="token"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.token}
                      />
                    </>
                  )}
                </Stack>
                <Button
                  fullWidth
                  size="large"
                  sx={{ mt: 3 }}
                  type="submit"
                  variant="contained"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Acessar"
                  )}
                </Button>
                {errorMessage && (
                  <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                    {errorMessage}
                  </Typography>
                )}
              </form>
            )}
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default SignIn;
